import React from "react"
import NewsletterFormUnsubscribe from "../../components/form/NewsletterFormUnsubscribe"
import Layout from "../../components/layouts/Default"
import H2 from "../../components/typography/H2"
import P from "../../components/typography/P"
import Container from "../../components/common/Container"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Zum Newsletter anmelden" desc="">
      <Container>
        <div className="max-w-2xl mx-auto">
          <H2 className="mb-4">Schade,</H2>
          <P className="mb-2">
            dass Du Dich von unserenm Newsletter abmelden möchten und nicht mehr
            informiert werden möchten.
          </P>{" "}
          <NewsletterFormUnsubscribe />
        </div>
      </Container>
    </Layout>
  )
}
