import axios from "axios"
import { Link, navigate } from "gatsby"
import React, { FormEvent, useState } from "react"
import Button from "../common/Button"
import Input from "./elements/Input"

export default function NewsletterFormUnsubscribe() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const validateMaindata = e => {
    e.preventDefault()
    let inputs = document
      ?.getElementById("newsletterform")
      ?.querySelectorAll("input")
    const errors: string[] = []

    if (!inputs) return

    inputs.forEach(input => {
      if (input.checkValidity() === false) {
        input.reportValidity()
        errors.push("Error")
      }
    })
  }
  const handleRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    validateMaindata(e)
    const body = {
      email,
    }
    const fetchData = async () => {
      try {
        setLoading(true)
        await axios.post("/.netlify/functions/unsubscribe-newsletter", body)
        navigate("/newsletter/abgemeldet")
      } catch (error) {
        setLoading(false)
        console.log(error.response)
        navigate("/newsletter/fehler/")
      }
    }
    fetchData()
  }

  return (
    <form
      name="Anfrage"
      method="POST"
      data-netlify="true"
      onSubmit={handleRegister}
      id="newsletterform"
    >
      <Input
        id="email-address"
        name="email-address"
        type="email"
        autoComplete="email"
        placeholder="E-Mail"
        label="Geben Sie Ihre E-Mail-Adresse ein, um sich anzumelden"
        value={email}
        onChange={e => setEmail(e.target.value)}
        className="w-full mb-6"
        required
      />

      <Button
        submit
        text={
          <>
            {loading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-block w-6 h-6 mr-3 animate-spin"
                width={44}
                height={44}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />
                <line x1="5.63" y1="7.16" x2="5.63" y2="7.17" />
                <line x1="4.06" y1={11} x2="4.06" y2="11.01" />
                <line x1="4.63" y1="15.1" x2="4.63" y2="15.11" />
                <line x1="7.16" y1="18.37" x2="7.16" y2="18.38" />
                <line x1={11} y1="19.94" x2={11} y2="19.95" />
              </svg>
            )}
            Abmelden
          </>
        }
        className="w-full"
      />
    </form>
  )
}
